const { defaultLanguage } = require('../../prismic-configuration')

/**
 * The Link Resolver used for the Prismic repository. This function converts a
 * Prismic document to a URL within your app. It is used throughout your app to
 * resolve document links and support editor previews.
 *
 * {@link https://prismic.io/docs/technologies/link-resolver-gatsby}
 *
 * @param doc Prismic document to resolve to a URL within your app.
 *
 * @returns URL for the provided Prismic document.
 *
 * @type import('@prismicio/helpers').LinkResolverFunction
 */
exports.linkResolver = (doc) => {
  // console.log('doc: ', doc)
  // console.log(doc.uid)
  switch (doc.type) {
    case 'mainpage': {
      return doc.lang === defaultLanguage ? '/' : `/${doc.lang}`
    }

    case 'search': {
      return `/search/`
    }
    case 'events': {
      return `/events/`
    }
    case 'videoblog': {
      return `/videoblogs/`
    }
		case 'wochenkarte': {
      return `/wochenkarte/`
    }
    case 'careers': {
      return `/stellenanzeigen-gastronomie-sindelfingen/`
    }
		case 'rental': {
      return `/veranstaltungsraum-sindelfingen-mieten/`
    }
    case 'blog': {
      return `/blog/`
    }

    case 'event': {
      return `/events/${doc.uid}`
    }
    case 'vb_item': {
      return `/videoblog/${doc.uid}`
    }
    case 'restaurant': {
      return `/restaurant/${doc.uid}`
    }
    case 'page': {
      return `/page/${doc.uid}`
    }
    case 'careers_item': {
      return `/stellenanzeigen-gastronomie-sindelfingen/${doc.uid}`
    }
		case 'rental_item': {
      return `/veranstaltungsraum-sindelfingen-mieten/${doc.uid}`
    }
    case 'blog_item': {
      return `/blog/${doc.uid}`
    }

    default:
      return '/'
  }
}
