exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-prismic-blog-item-uid-js": () => import("./../../../src/pages/blog/{prismicBlogItem.uid}.js" /* webpackChunkName: "component---src-pages-blog-prismic-blog-item-uid-js" */),
  "component---src-pages-events-prismic-event-uid-js": () => import("./../../../src/pages/events/{prismicEvent.uid}.js" /* webpackChunkName: "component---src-pages-events-prismic-event-uid-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-restaurant-prismic-restaurant-detail-uid-js": () => import("./../../../src/pages/restaurant/{prismicRestaurantDetail.uid}.js" /* webpackChunkName: "component---src-pages-restaurant-prismic-restaurant-detail-uid-js" */),
  "component---src-pages-stellenanzeigen-gastronomie-sindelfingen-prismic-careers-item-uid-js": () => import("./../../../src/pages/stellenanzeigen-gastronomie-sindelfingen/{prismicCareersItem.uid}.js" /* webpackChunkName: "component---src-pages-stellenanzeigen-gastronomie-sindelfingen-prismic-careers-item-uid-js" */),
  "component---src-pages-veranstaltungsraum-sindelfingen-mieten-prismic-rental-item-uid-js": () => import("./../../../src/pages/veranstaltungsraum-sindelfingen-mieten/{prismicRentalItem.uid}.js" /* webpackChunkName: "component---src-pages-veranstaltungsraum-sindelfingen-mieten-prismic-rental-item-uid-js" */),
  "component---src-pages-wochenkarte-prismic-menu-day-uid-js": () => import("./../../../src/pages/wochenkarte/{prismicMenuDay.uid}.js" /* webpackChunkName: "component---src-pages-wochenkarte-prismic-menu-day-uid-js" */),
  "component---src-pages-wochenkarte-week-number-rest-code-js": () => import("./../../../src/pages/wochenkarte/[weekNumber]/[restCode].js" /* webpackChunkName: "component---src-pages-wochenkarte-week-number-rest-code-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-careers-js": () => import("./../../../src/templates/careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-events-js": () => import("./../../../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-mainpage-js": () => import("./../../../src/templates/mainpage.js" /* webpackChunkName: "component---src-templates-mainpage-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-rental-js": () => import("./../../../src/templates/rental.js" /* webpackChunkName: "component---src-templates-rental-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-videoblog-js": () => import("./../../../src/templates/videoblog.js" /* webpackChunkName: "component---src-templates-videoblog-js" */)
}

